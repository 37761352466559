<template>
    <div class="Traincourse">
        <div class="introduce">
            <img src="../../assets/images/exam/comp.png" alt="">
            <h1>网培师培训课程介绍</h1>
            <p>网培师培训课程是针对网培师评测体系制作的教学培训课程,由网培师平台制作并上传,主要用于网培师考生的考前知识培训,课程内容包括:"教学综合能力评测"、“网培政策规范评测”、“教学数字化能力评测“三个科目的课程教学,考生可通过课程的培训学习来提高自身的网络教学能力,以此保证通过考试。网培师平台建议考生先学习课程,再通过模拟练习打磨知识,最后再参加正式考试,从而顺利的获得证书!</p>
            <el-button @click="study">立即参加课程培训>></el-button>
        </div>


        <img src="../../assets/images/exam/title1.png" alt="" class="img_title topimg">
        <div class="info">
            <div class="info_txt">
                <el-row>
                    <el-col :span="2">
                        <img src="../../assets/images/exam/01.png" alt="" class="serial">
                    </el-col>
                    <el-col :span='22'>
                        <p>网培师培训课程是针对网培师评测体系制作的教学培训课程，由网培师平台邀请名师制作，主要用于网培师考生的考前知识培训。</p>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="2">
                        <img src="../../assets/images/exam/02.png" alt="" class="serial">
                    </el-col>
                    <el-col :span='22'>
                        <p>课程内容包括：”教学综合能力评测“”网培政策规范评测““教学数字化能力评测”三个科目的课程教学，考生可通过课程的培训学习来提高自身的网络教学能力，以此来保证通过考试。</p>
                    </el-col>
                </el-row>
            </div>
        </div>

        <div class="ability">
            <div><img src="../../assets/images/exam/title2.png" alt="" class="img_title lang"></div>
            <div class="imgs_item">
                <h2>核心能力</h2>
                <el-carousel :interval="8000" arrow="always" indicator-position="outside" height="220px" ref="carousel1" v-touch:swipeleft="leftSlide" v-touch:swiperight="rightSlide">
                    <el-carousel-item v-for="(item,index) in competence" :key="index">
                       <img :src="item" alt="">
                    </el-carousel-item>
                </el-carousel>
                <h2>应用场景</h2>
                <el-carousel :interval="8000" arrow="always" indicator-position="outside" height="220px" ref="carousel2" v-touch:swipeleft="left" v-touch:swiperight="right">
                    <el-carousel-item v-for="(item,index) in scene" :key="index">
                       <img :src="item" alt="">
                    </el-carousel-item>
                </el-carousel>
            </div>                       
        </div>

        <img src="../../assets/images/exam/title3.png" alt="" class="img_title topimg">
        <div class="info">
            <div class="row">
                <div class="target_item">
                        <img src="../../assets/images/exam/001.png" alt="" class="tag">
                    <div class="cont">
                        <img src="../../assets/images/exam/-s-icon01.png" alt="" class="cover">
                        <p>培养学员的网络教学能力</p>
                    </div>
                </div>                   
            
                <img src="../../assets/images/guide/arrowright.png" alt="" class="arrow">

                <div class="target_item">
                        <img src="../../assets/images/exam/002.png" alt="" class="tag">
                    <div class="cont">
                        <img src="../../assets/images/exam/-s-icon02.png" alt="" class="cover">
                        <p>培养学员的网络教学能力</p>
                    </div>
                </div>    

                <img src="../../assets/images/guide/arrowright.png" alt="" class="arrow">

                <div class="target_item">
                        <img src="../../assets/images/exam/003.png" alt="" class="tag">
                    <div class="cont">
                        <img src="../../assets/images/exam/-s-icon03.png" alt="" class="cover">
                        <p>培养学员的网络教学能力</p>
                    </div>
                </div>     

                <img src="../../assets/images/guide/arrowright.png" alt="" class="arrow">

                <div class="target_item">
                        <img src="../../assets/images/exam/004.png" alt="" class="tag">
                    <div class="cont">
                        <img src="../../assets/images/exam/-s-icon04.png" alt="" class="cover">
                        <p>培养学员的网络教学能力</p>
                    </div>
                </div>     
                
                <img src="../../assets/images/guide/arrowright.png" alt="" class="arrow">

                <div class="target_item">
                        <img src="../../assets/images/exam/005.png" alt="" class="tag">
                    <div class="cont">
                        <img src="../../assets/images/exam/-s-icon05.png" alt="" class="cover">
                        <p>培养学员的网络教学能力</p>
                    </div>
                </div>                    
            </div>
        </div>

        <div class="why">
            <div><img src="../../assets/images/exam/title4.png" alt="" class="img_title"></div>
            <div class="card">
                <ul>
                    <li><p>因为网培师评测体系是检验学员是否已经具备了网络教学的能力，所以评测的内容及知识比较繁多，要求学员熟练掌握知识才可通过评测。</p></li>
                    <br/>
                    <li><p>网培师平台建议学员<span>先学习网培师课程和参加模拟练习后</span>再前往正式考试。</p></li>
                </ul>                            
            </div>
            <div class="ban">
                <el-button @click="study">立即参加课程培训>></el-button>
            </div>
        </div>

        <div class="Mask">
            <div class="what">
                <div><img src="../../assets/images/exam/title5.png" alt="" class="img_title"></div>
                <div class="item">
                    <img src="../../assets/images/exam/0001.png" alt="">
                    <span>成为网培师所需的能力</span>
                </div>
                <div class="item">
                    <img src="../../assets/images/exam/0002.png" alt="">
                    <span>教育学知识和教育心理学知识</span>
                </div>
                <div class="item">
                    <img src="../../assets/images/exam/0003.png" alt="">
                    <span>网络教学的政策与规范</span>
                </div>
                <div class="item">
                    <img src="../../assets/images/exam/0004.png" alt="">
                    <span>教学数字工具的应用</span>
                </div>
            </div>
        </div>

        <!-- 支付弹框 -->
        <el-dialog title="支付" :visible.sync="dialogVisible" width="85vw" v-loading="loading" :close-on-click-modal="false" center class="payStyle">
            <div>
                <span>付费内容:</span>
                培训教材、评测练习、正式评测、评测审核、证书制作、证书颁发。
            </div>
            <div v-if="payRadio !== '1'">
                <span>输入兑换码: </span>
                <el-input placeholder="请输入兑换码" class="redeem" v-model="redeemData"></el-input>
                <p class="warnRed">*请注意区分大小写</p>
            </div>
            <div v-else><span>支付金额: </span>{{ this.$price }}</div>
            <div>
                <p><span>支付方式:</span></p>
                <el-radio v-model="payRadio" label="1" border>微信支付</el-radio>
                <el-radio v-model="payRadio" label="2" border>兑换码</el-radio>
            </div>
            <el-button type="danger" @click="toPay" v-if="payRadio == '1'"
                >立即支付</el-button>
            <el-button type="danger" @click="toRedeem" v-else>立即兑换</el-button>
        </el-dialog>
    </div>
</template>
<script>
let _this
export default {
    data(){
        return{
            activeName: 'first',
            dialogVisible: false,
            studyed: false,
            loading: false,
            payRadio: '1',
            form: {},
            obj: {},
            redeemData: '',
            oftop:'',
            top:'',
            token:'',
            competence:[
                require('../../assets/images/exam/item1.png'),
                require('../../assets/images/exam/item2.png'),
                require('../../assets/images/exam/item3.png'),
                require('../../assets/images/exam/item4.png')
            ],
            scene:[
                require('../../assets/images/exam/item5.png'),
                require('../../assets/images/exam/item6.png'),
                require('../../assets/images/exam/item7.png')
            ]
        }
    },
    created(){
        _this = this
        _this.obj = this.$store.state.wps_user
        if(_this.$route.params.isfirst=='first'){
           _this.dialogVisible = true
        }
    },
    mounted(){
        this.oftop = document.getElementsByClassName('el-tabs__header is-left')[0]
        window.addEventListener('scroll',this.handleScrollx)
    },
    methods:{
        // handleScrollx() {
        //     //获取滑动条位置
        //     this.top= 0
        //     if (document.documentElement && document.documentElement.scrollTop) {
        //          this.top = document.documentElement.scrollTop;
        //     } else if (document.body) {
        //          this.top = document.body.scrollTop;
        //     }
        //     if( this.top>=425){
        //         this.oftop.setAttribute('style', 'position: fixed;top:150px;');
        //         document.getElementsByClassName('el-tabs__content')[1].setAttribute('style','margin-left:190px')
        //     }else if(this.top<=425){
        //         this.oftop.setAttribute('style', 'position:relative;');
        //     }
        // },
        leftSlide() {
            this.$refs.carousel1.next();
        },
        rightSlide() {
            this.$refs.carousel1.prev();
        },
        left() {
            this.$refs.carousel2.next();
        },
        right() {
            this.$refs.carousel2.prev();
        },
        study() {
            _this.studyed = true
            if (this.$store.state.wps_user.mobile) {
                _this.$http.post(process.env.VUE_APP_URL + 'isPay', { good_id: 1 })
                .then(data => {
                    this.token=data.data.token
                    if (data.data.code == 403) {
                        _this.$message.error(data.data.msg)
                        return
                    }
                    if (data.data.code == 200) {
                        _this.dialogVisible = true
                    }else {
                        _this.setNetbanCookie()
                    }
                })
                .catch(err => {
                    _this.$message.error('网络错误，请重试')
                    console.log(err)
                })
            } else {
                this.$confirm('需要登录，请先登录账号', '提示', {
                    confirmButtonText: '登录',
                    cancelButtonText: '取消',
                    type: 'warning',
                    customClass: 'message-logout'
                }).then(() => {
                    this.$router.push({ path: '/login' })
                }).catch(() => {
                          
                });
                // this.$message.warning('您还未登录，请先登录！')
            }
        },
        setNetbanCookie() {
            _this.$http.post(process.env.VUE_APP_URL + 'isPay', { good_id: 1 })
            .then(data => {
                _this.token=data.data.token
                 window.location.href='http://netban.cn/api/user/synwp/' +
                _this.token +
                '?url=http://custom.netban.cn/app/pc/?id=T20326'
            })
        },
        toRedeem() {
        //兑换码
        _this.loading = true
        let subsystem_url = window.location.href
        _this.$http
            .post(process.env.VUE_APP_URL + 'exchange', {
            good_id: 1,
            numbers: this.redeemData,
            subsystem_url: subsystem_url,
            })
            .then(data => {
            _this.loading = false
            if (data.data.code == 403 || data.data.code == 500) {
                _this.$message.error(data.data.msg)
                return
            }

            _this.redeem_number = data.data.data
            if (_this.redeem_number) {
                window.location.href =
                'http://pay.netban.cn/pay/' + this.redeem_number //跳转至支付页面
            }
            if (!_this.obj.is_verfy) {
                _this.warningDialog = true
            } else {
                if (_this.toForm.istrue) {
                _this.papers(_this.toForm.istrue)
                } else {
                _this.setNetbanCookie()
                }
            }
            _this.dialogVisible = false
            })
            .catch(err => {
            _this.$message.error('网络错误，请重试')
            _this.loading = false
            console.log(err)
            })
        },
        toPay() {
            //去支付
            this.toExam()
        },
        toExam() {
            let subsystem_url = window.location.href
            _this.$http.post(process.env.VUE_APP_URL + 'order', {good_id: 1,subsystem_url: subsystem_url,})
            .then(data => {
                if (data.data.code == 200) {
                    //未付款
                    _this.order_number = data.data.data
                    window.location.href =
                    'http://pay.netban.cn/pay/' + this.order_number //跳转至支付页面
                    // _this.dialogVisible = true;
                } else if (data.data.code == 201&&data.data.token!='') {
                //    _this.$message.error(data.data.msg)
                    _this.setNetbanCookie()
                } else {
                    _this.$message.error(data.data.msg)
                }
            }).catch(err => {
                _this.$message.error('网络错误，请重试')
                console.log(err)
            })
        },
    },
    directives: {
    touch: {
      bind: function (el, binding) {
        var touchType = binding.arg; //传入的模式 press swipeRight swipeLeft swipeTop swipeDowm Tap
        var timeOutEvent = 0;
        var direction = "";
        //滑动处理
        var startX, startY;

        //返回角度
        function GetSlideAngle(dx, dy) {
          return (Math.atan2(dy, dx) * 180) / Math.PI;
        }

        //根据起点和终点返回方向 1：向上，2：向下，3：向左，4：向右,0：未滑动
        function GetSlideDirection(startX, startY, endX, endY) {
          var dy = startY - endY;
          var dx = endX - startX;
          var result = 0;

          //如果滑动距离太短
          if (Math.abs(dx) < 100 && Math.abs(dy) < 100) {
            return result;
          }

          var angle = GetSlideAngle(dx, dy);
          if (angle >= -45 && angle < 45) {
            result = "swiperight";
          } else if (angle >= 45 && angle < 135) {
            result = "swipeup";
          } else if (angle >= -135 && angle < -45) {
            result = "swipedown";
          } else if (
            (angle >= 135 && angle <= 180) ||
            (angle >= -180 && angle < -135)
          ) {
            result = "swipeleft";
          }
          return result;
        }

        el.addEventListener(
          "touchstart",
          function (ev) {
            startX = ev.touches[0].pageX;
            startY = ev.touches[0].pageY;

            //判断长按
            timeOutEvent = setTimeout(() => {
              timeOutEvent = 0;
              if (touchType === "press") {
                binding.value();
              }
            }, 500);
          },
          false
        );

        el.addEventListener("touchmove", function () {
          clearTimeout(timeOutEvent);
          timeOutEvent = 0;
        });

        el.addEventListener(
          "touchend",
          function (ev) {
            var endX, endY;
            endX = ev.changedTouches[0].pageX;
            endY = ev.changedTouches[0].pageY;
            direction = GetSlideDirection(startX, startY, endX, endY);
            clearTimeout(timeOutEvent);
            switch (direction) {
              case 0:
                break;
              case "swipeup":
                if (touchType === "swipeup") {
                  binding.value();
                }
                break;
              case "swipedown":
                if (touchType === "swipedown") {
                  binding.value();
                }
                break;
              case "swipeleft":
                if (touchType === "swipeleft") {
                  binding.value();
                }
                break;
              case "swiperight":
                if (touchType === "swiperight") {
                  binding.value();
                }
                break;
              default:
            }
          },
          false
        );
      },
    },
  },
}
</script>
<style lang="less" scoped>
.Traincourse{
    background-color: #fcf8f5;
}
.introduce{
    width: 100%;
    text-align: center;
    background: #FFFFFF;
    padding: 20px 0px;
    h1{
        font-size: 20px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #000005;
    }
    p{
        width: 85vw;
        margin: 10px auto 20px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #7C8087;
        line-height: 28px;
        text-align: left;
    }
    img{
        width: 100%;;
    }
    .el-button{
        width: 252px;
        height: 44px;
        background: #960F23;
        border-radius: 4px;
        color:#FFFFFF;
        font-size: 16px;
        margin: 0px auto;
    }
}
.topimg{
  margin-top: 20px;
}
.info{
    // 介绍
    max-width: 1200px;
    padding:20px 20px 30px;
    margin: 0px auto;
    .serial{
        margin-top: 5px;
    }
    .row{
        white-space: nowrap;
        overflow-x: scroll;
        overflow-y: hidden;
    }
    .row::-webkit-scrollbar{
        display: none;
    }
    .info_txt{
        padding:20px 20px 10px;
        border:1px dashed #C8A35E;
        p{
            font-size: 16px;
            margin:0px 0px 10px 10px;
            line-height:25px;
        }
    }
    // 目标
    .target_item{
        background-color: #FFFFFF;
        padding: 10px auto 0px;
        width: 50vw;
        white-space: nowrap;
        display: inline-block;
        margin-left: 20px;
        .tag{
            width:95%;
            position: relative;
            right:5%;
            top: 20px;
        }
        .cont{
            width: 65%;
            margin: 10px auto 20px;
            .cover{
                width: 60%;
                margin: 20% 0px 15px 20%;
            }
            p{
                text-align: center;
                font-size: 16px;
                font-family: Source Han Sans CN;
                font-weight: 400;
                color: #2B2B2B;
                line-height: 20px;
                white-space: normal;
            }
        }
    }
    .arrow {
        width: 20%!important;
        margin: 0px 0px 40px 20px;
    }
}
.ability{
    padding:20px 0px 30px;
    background-color: #FFFFFF;
    /deep/.el-carousel__container{
        max-width: 85vw;
        margin: 0px auto;
    }
    .imgs_item{
        max-width: 1200px;
        margin: 0px auto;
        h2{
            font-size: 17px;
            font-family: Alibaba PuHuiTi;
            font-weight: bold;
            color: #333333;
            margin:15px auto;
            text-align: center;
        }
        img{
            width: 100%;
        }
    }
}
.why{
    padding:25px 0px 0px;
    background-color: #FFFFFF;
    .card{
        max-width: 85vw;
        margin:0px auto;
        padding: 25px 10px;
        background: #FFFFFF;
        box-shadow: 0px 2px 21px 0px rgba(134, 99, 69, 0.14);
        position: relative;
        z-index: 1;
        p{
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #333333;
            line-height: 28px;
            position: relative;
            top: -4px;
        }
        span{
            color: #B72F24;
        }
    }
    .ban{
        width: 100%;
        height: 153px;
        background: #960F23;
        text-align: center;
        position: relative;
        bottom: 76px;
        .el-button{
            width: 252px;
            height: 44px;
            margin-top: 54.5px;
            background: #CE9A6B;
            border-radius: 4px;
            color:#FFFFFF;
            font-size: 16px;
            border: none;
            position: relative;
            z-index: 60;
        }
    }
}
.what{
    margin: 0px auto;
    .item{
        margin-left: 2%;
        width: 90%;
        height: 80px;
        background-color: #FFFFFF;
        margin: 20px auto;
        display: flex;
        align-items: center;
        img{
            width: 10%;
            margin-left: 20px;
        }
        span{
            font-size: 19px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #333333;
            margin-left: 20px;
        }
    }
}
.Mask{
    background-color: #FCF8F5;
    padding: 20px 0px;
}
.el-dialog {
    width: 80vw;
    .el-input {
      width: 270px;
      margin: 10px 0;
    }
    .el-button--danger {
      background-color: #960f23;
      width: 100px;
      margin-top: 50px;
      margin-left:31%;
    }
  }
  .uploading {
    margin-bottom: 100px;
  }
  .redeem {
    padding: 10px 10px 0 0;
  }
  .toRedeem {
    width: 120px !important;
    margin-left: 14px;
  }
  .cancel {
    margin-right: 225px;
  }
  .examselect {
    p {
      margin: 12px;
      font-size: 15px;
      span {
        color: red;
      }
    }
    div {
      padding-top: 20px;
      .el-button {
        width: 110px;
      }
    }
  }
  .el-radio {
    margin: 15px 0px;
  }
  .download {
    padding-top: 10px;
    font-size: 15px;
    .el-link {
      color: red;
      font-size: 16px;
    }
  }
  .toPdf {
    h2 {
      margin-bottom: 25px;
      font-size: 20px;
      color: #960f23;
    }
    div {
      margin: 5px;
      font-size: 16px;
    }
  }
  .dialog_table {
    .container {
      height: 70vh;
    }
    /deep/.el-dialog__header {
      text-align: center;
      font-weight: bold;
      color: #000000;
    }
    /deep/.el-dialog__body {
      padding: 10px 100px 20px;
    }
  }
  /deep/.el-form-item{
    margin: 0px;
  }
  .warnRed {
    color: red;
    font-size: 13px;
  }
  .payStyle {
    div {
      font-size: 15px;
      margin: 10px;
    }
    span {
      font-size: 16px;
      color: #960f23;
      padding-right: 10px;
    }
    .el-button {
      margin: 30px 30%;
    }
}
/deep/.el-form-item__label{
      padding:10px 0px 0px!important;
    }
/deep/.el-form-item__error{
  padding-top:0px;
}
</style>